import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  clientVersion: '1.52.0-44-g7187f158',
  logLevel: NgxLoggerLevel.INFO,
  API_HOST: 'https://' + window.location.hostname,
  AUTH0_DOMAIN_SPA: 'auth.49ing.ch',
  AUTH0_CLIENT_ID_SPA: 'CR79VmjedmA36uHzdt2ueD2n7LUsIA1U'
};
